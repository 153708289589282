<template>
    <div>
        <vx-card class="h-full order-planned-sales pt-4 pb-8" >

            <h2>Sprint Planer</h2>

            <div class="vx-row">

                <div class="vx-col md:w-3/12 w-full mb-1">
                   <offer-sprint-list></offer-sprint-list>
                    <div>
                        <router-link  :to="{name:'offer-sprint-planer'}">
                            <vs-button  size="small"
                                        class="ml-6"
                                        icon-pack="feather"
                                        icon="icon-plus"
                                        icon-before
                                        color="dark"
                                        type="filled"
                                       >Neuer Sprint erstellen</vs-button>
                        </router-link>
                    </div>
                </div>

                <div class="vx-col md:w-9/12 w-full mb-1">
                    <sprint-detail v-if="$route.params.sprintId" :key="$route.params.sprintId"></sprint-detail>
                    <create-sprint v-else></create-sprint>

                </div>
            </div>

        </vx-card>
    </div>
</template>

<script>
import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'

import ApiService from "../../../api";
import moment from 'moment';
import QueryHelper from "../../../mixins/helper/query.helper";
import * as qs from "qs";
import PriceHelper from "../../../mixins/helper/price.helper";
import vSelect from 'vue-select'
import {mapGetters} from "vuex";
import GravatarImg from "vue-gravatar/src/components/GravatarImg";
import cloneDeep from "lodash/cloneDeep";
import OfferSprintList from "@/views/distribution/offers/sprintplanner/OfferSprintList.vue";
import CreateSprint from "@/views/distribution/offers/sprintplanner/CreateForm.vue";
import SprintDetail from "@/views/distribution/offers/sprintplanner/SprintDetail.vue";

export default {
    name: "SprintPlaner",
    components: {
        SprintDetail,
        CreateSprint,
        OfferSprintList,
        VueBootstrap4Table,
        'v-select': vSelect,
        GravatarImg
    },
    data() {
        return {
            data: [],
            selectedSprint: null,
        }
    },
    created() {

    },
    computed: {
        ...mapGetters(['userHasPermission', 'userId', 'user']),

    },
    methods: {




    },

}
</script>

<style lang="scss">

</style>
