<template>
    <div>
        <vs-dropdown class="mr-6 status-btn mb-2"  >
            <a class="a-icon" href="#">
                E-Mail Aktionen
                <vs-icon class="" icon="expand_more"></vs-icon>
            </a>

            <vs-dropdown-menu>
                <vs-dropdown-item  v-for="item in templates" @click="onSelectTemplate(item)"  class="dropdown-mail-action-item">
                    {{ (item.matchcode) ? item.matchcode : item.subject}}
                </vs-dropdown-item>
            </vs-dropdown-menu>
        </vs-dropdown>

        <vs-popup class="email-action-modal" title="E-Mail versenden" v-if="selectedTemplate" :active.sync="modalActive" @close="onClose"  classContent="scrollable">

            <div class="flex">
                <div class="w-1/2 pr-2">
                    <vs-input label="Absender" v-model="emailFrom" class="w-full mb-2" readonly></vs-input>
                </div>
                <div class="w-1/2 pl-2">
                    <label class="vs-input--label --label">Empfänger</label>
                    <email-suggestion-input v-model="emailAddresses" autocomplete="off" class="w-full"></email-suggestion-input>
                </div>
            </div>

            <div class="flex">
                <div class="w-1/2 pr-2">
                    <label class="vs-input--label">CC</label>
                    <email-suggestion-input v-model="emailCC" class="w-full"></email-suggestion-input>
                </div>
                <div class="w-1/2 pl-2">
                    <label class="vs-input--label">BCC</label>
                    <email-suggestion-input v-model="emailBCC" class="w-full"></email-suggestion-input>
                </div>
            </div>

            <vs-input label="Betreff" v-model="emailSubject" class="w-full mb-4"></vs-input>

            <p class="text-sm mb-2">Nachricht</p>

            <div class="w-full mb-5">
                <ckeditor :editor="editor" v-model="mailTemplate" :config="editorConfig" class="w-full"></ckeditor>
            </div>
            <vs-checkbox v-model="personalEmail" class="mb-4">Als persönliche Mail mit Mitarbeiter Signatur versenden</vs-checkbox>



            <div class="flex justify-end mt-6">
                <vs-button color="danger" type="border" class="mr-auto" @click="onClose">Abbrechen</vs-button>
                <vs-button color="success" class="ml-2" @click="onClickSend">E-Mail versenden</vs-button>
            </div>

        </vs-popup>
    </div>
</template>
<script>
import ApiService from "../../api";
import moment from "moment";
import MgSelect from "@/components/mg-select/MgSelect.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Gravatar from "vue-gravatar";
import EmailSuggestionInput from "@/components/search/EmailSuggestionInput.vue";
import codeMirrorOptions from "@/views/administration/email-templates/components/data/codemirror-options";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
export default {
    name: "EmailActionDropdown",

    components: {
        EmailSuggestionInput,
        MgSelect,
    },
    props: {
        data: {
            type: Object | Array,
            default: () => {
                return null
            }
        },
        type: {
            type: String,
        },
        to: {
            type: String
        },
        objectId: {
            type: Number | String
        },
        objectType: {
            type: String
        },
    },
    data() {
        return {
            modalActive: false,
            templates: [],
            selectedTemplate: null,
            compiledTemplate: null,

            editor: ClassicEditor,
            editorConfig: {
                toolbar: [ 'bold', 'italic', '|', 'link' ],
                allowedContent : true,
                extraAllowedContent: 'div(*)',
                removeFormatAttributes: '',
            },

            codeMirrorOptions: codeMirrorOptions,
            emailAddresses: [],
            mailTemplate: '',
            cmInstance: null,
            additionalAttachments: [],
            emailFrom: null,
            emailCC: [],
            emailBCC: [],
            emailSubject: null,
            editorOptions: {
                showGutter: true
            },
            personalEmail: true,
            toolbar: [
                ['bold', 'italic', 'underline'],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }, { 'font': [] }],
                [{ 'color': [] }, { 'background': [] }],
                [{ 'script': 'sub'}, { 'script': 'super' }, { 'align': [] }],
                ['link'],
            ],

        }
    },
    computed: {
    },
    created() {
        this.fetchMailTemplates()
    },
    mounted() {
    },
    methods: {
        fetchMailTemplates() {
            this.$vs.loading();

            ApiService.get('mail-templates/category/'+this.type)
                .then((response) => {
                    if (response.data.status !== 'success') {
                        return false;
                    }

                    this.templates = response.data.result;
                })
                .catch((error) => {
                })
                .finally(() => {
                    this.$vs.loading.close();
                });
        },
        fetchCompiledTemplate(template) {
            this.$vs.loading();

            const data = {
                to: this.to,
                mailData: this.data
            };

            return ApiService.post('mail-templates/compile/'+template.id, data)
                .then((response) => {
                    if (response.data.status !== 'success') {
                        return false;
                    }

                    this.compiledTemplate = response.data.result;
                    return true;
                })
                .catch((error) => {
                })
                .finally(() => {
                    this.$vs.loading.close();
                });

        },
        onSelectTemplate(template) {
            this.selectedTemplate = template;
            this.compiledTemplate = null;
            this.modalActive = true;
            this.fetchCompiledTemplate(template).then(() => {
                this.setupModalData();
            });

        },
        setupModalData(){
            this.emailAddresses = this.to.split(';');
            this.mailTemplate = '';

            if(this.compiledTemplate.preset_bcc) {
                this.emailBCC = this.compiledTemplate.preset_bcc.split(';');
            } else {
                this.emailBCC = [];
            }

            if(this.compiledTemplate.preset_cc) {
                this.emailCC = this.compiledTemplate.preset_cc.split(';');
            } else {
                this.emailCC = [];
            }

            if(this.compiledTemplate.is_html) {
                this.mailTemplate = this.compiledTemplate.html_content.replace(/\n|\r/g, "");
            } else {
                this.mailTemplate = this.compiledTemplate.plain_content.replace(/\n|\r/g, "");
            }


            this.emailFrom = this.compiledTemplate.from_email;
            this.emailSubject = this.compiledTemplate.subject;

        },
        onChangeStatus(item) {
            this.$emit('on-change-status', item)
        },
        onClose() {
            this.selectedTemplate = null;
            this.modalActive = false;
            this.$emit('close')
        },
        editorInit (editor) {
            require('ace-builds/src-min-noconflict/ext-language_tools');
            require('ace-builds/src-min-noconflict/mode-php_laravel_blade');
            require('ace-builds/src-min-noconflict/theme-tomorrow_night_bright');
            editor.session.setMode("ace/mode/php");
            editor.setOptions(this.editorOptions);
        },
        replaceCommaToSemicolon(value){
            if(!value) {
                return '';
            }
            if (typeof value === 'string') {
                return value.replace(/,/g, ';');
            }
            if (typeof value === 'object' && value.length === 0) {
                return '';
            }
            if (Array.isArray(value) && value.length >= 1) {
                return value.join(';');
            }
            return '';
        },
        sendMailAction(){
            let formData = new FormData();

            formData.append('objectType', this.objectType);
            formData.append('objectId', this.objectId);
            formData.append('emails', this.replaceCommaToSemicolon(this.emailAddresses));
            formData.append('content', this.mailTemplate);
            formData.append('mail_template_id', this.selectedTemplate.id);
            formData.append('subject', this.emailSubject);
            formData.append('from', this.emailFrom);
            formData.append('cc', this.replaceCommaToSemicolon(this.emailCC));
            formData.append('bcc', this.replaceCommaToSemicolon(this.emailBCC));
            formData.append('personal', this.personalEmail ? 1:0);
            formData.append('mailData', JSON.stringify(this.data));

            this.$vs.loading();

            return ApiService.post('mail-templates/send-compiled-mail', formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((response) => {
                this.$vs.notify({
                    title: 'Erfolgreich',
                    text: 'Die Email wurde erfolgreich versendet',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                });
            }).catch((error) => {
                this.$vs.loading.close();

                if (error.response.data.message) {
                    this.$vs.notify({
                        title: 'Fehler',
                        text: error.response.data.message,
                        time: 6000,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                } else {
                    this.$vs.notify({
                        title: 'Fehler',
                        text: error.message,
                        time: 6000,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                }

            });
        },
        onClickSend() {
            this.sendMailAction().then((response) => {
                console.log(response);
               // this.selectedTemplate = null;
                this.$vs.loading.close();
            })
        },
    },
}
</script>
<style lang="scss">
.action-button {

    .vs-con-dropdown {
        padding: 10px 15px;
        background-color: #ec7b24;
        border-radius: 5px;

        a {
            color: #ffffff !important;
            display: flex;
            .vs-icon {
                padding-left: 3px;
                font-size: 1.3em;
            }
        }
        &:hover {
            cursor: pointer;
        }
        &.gray {
            background-color: #636363;
        }
        &.success {
            background-color: #71c73f;
        }
        &.orange {
            background-color: #ec7b24;
        }
        &.red {
            background-color: #ea3540;
        }
    }
    .vs-con-dropdown {
        padding: 10px 15px;
        background-color: #ec7b24;
        border-radius: 5px;
        a {
            color: #ffffff !important;
            display: flex;
            .vs-icon {
                padding-left: 3px;
                font-size: 1.3em;
            }
        }
    }
}
.dropdown-mail-action-item {
    width: 400px;
}
.vs-popup--content.scrollable {
    overflow:auto;
}
</style>
