<template>
    <vx-card title="Sprints" ref="card">
        <div class="vx-row mb-6 mt-0">
            <div class="vx-col w-full">
                <vx-input-group class="mb-0">
                    <vs-input v-model="searchTerm" placeholder="Suchen ..."></vs-input>
                </vx-input-group>
            </div>
        </div>

        <VuePerfectScrollbar style="max-height: 320px">
            <vs-list>
                <vs-list-item v-for="sprint in filteredSprints" class="offer-sprint-list-item" v-bind:class = "(isSelected(sprint))?'current-active':''" :key="sprint.id">
                    <div class="list-titles">
                        <div class="vs-list--title">{{ sprint.name }}</div>
                        <div class="vs-list--subtitle" v-if="sprint.is_planned">
                            <div class="status-circle" :style="{'background-color':sprint.status_color}"></div>
                            {{ formatDate(sprint.start_date) }} - {{ formatDate(sprint.end_date) }}
                        </div>
                        <div class="vs-list--subtitle" v-else>
                            <div class="status-circle" :style="{'background-color':sprint.status_color}"></div>
                            {{sprint.status_name}}
                        </div>
                    </div>
                    <vs-button color="primary" size="small" :disabled="isSelected(sprint)" @click="selectSprint(sprint)"
                               style="margin-left:auto">
                        Auswählen
                    </vs-button>
                </vs-list-item>
            </vs-list>
        </VuePerfectScrollbar>

    </vx-card>
</template>

<script>
import ApiService from "../../../../api";
import moment from "moment";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import * as qs from "qs";

export default {
    name: "OfferSprintList",

    components: {
        VuePerfectScrollbar
    },

    data() {
        return {
            sprints: [],
            searchTerm: null,
            isLoading: false
        }
    },

    computed: {
        filteredSprints() {
            if (!this.searchTerm) {
                return this.sprints;
            }

            const term = this.searchTerm.toLowerCase();

            return this.sprints.filter(sprint => {
                return sprint.name.toLowerCase().includes(term);
            });
        },
        requestParams() {
            return {
                order: {
                    'field': 'created_at',
                    'direction': 'DESC'
                },
                filter: [{
                    field: 'offer_id',
                    value: this.$route.params.id,
                    expression: 'exact',
                }]
            }
        }
    },

    created() {
    },

    mounted() {
        this.fetchData();
    },

    methods: {
        fetchData: function () {
            this.$refs.card.refreshcard();

            return ApiService.get('project/sprint', {
                params: this.requestParams,
                paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
            }).then(response => {
                this.sprints = response.data.data.result;
                this.$refs.card.removeRefreshAnimation();
            });
        },
        listItemSubtitle(sprint) {
            if (sprint.status.is_planned) {
                return moment(sprint.status.start_date).format('DD.MM.YYYY') + ' - ' + moment(sprint.status.end_date).format('DD.MM.YYYY');
            }
            return '<span>test</span>';
        },

        selectSprint(sprint) {
            this.$router.push({
                name: 'offer-sprint-planer-detail', params: {
                    sprintId: sprint.id
                }
            });
        },

        isSelected(sprint) {
            if (!this.$route.params.sprintId) {
                return false;
            }

            return parseInt(sprint.id) === parseInt(this.$route.params.sprintId);
        },
        formatDate(date) {
            if (!date) {
                return 'Kein Datum';
            }

            return moment(date).format('DD.MM.YYYY');
        }
    },


}
</script>

<style>
.vs-list--item.offer-sprint-list-item .vs-list--slot {
    margin-left: unset;
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    -ms-flex-pack: start;
    -webkit-box-pack: start;
    justify-content: flex-start;
}

.current-active {
    background: #ebebeb;
    padding-top: 7px;
}

.status-circle {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    position: relative;
    display: inline-block;
    margin-right: 4px;
    top: 1px;
}
</style>