<template>

    <div>
        <vx-card title="Sprint bearbeiten">

            <div class="action-button">
                <vs-row>
                    <div class="vx-col md:w-1/4 w-full pr-2 ">
                        <vs-select v-model="sprint.status" label="Status" type="text" name="status" class="w-full"
                                   v-validate="'required'"
                                   data-vv-as="Status" :danger="errors.has('status')">
                            <vs-select-item v-for="status in statusList" :key="status.id" :value="status.id"
                                            :text="status.name"></vs-select-item>
                        </vs-select>
                    </div>
                    <div class="vx-col md:w-1/4 w-full pt-3 pr-2">
                        <vs-button color="warning" @click="onAddEvent" class="mt-2" :disabled="sprint && sprint.internal_participants && sprint.internal_participants.length === 0">Termin hinzufügen</vs-button>
                        <event-popup :active.sync="eventPopupActive"
                                     :event="currentEvent"
                                     @saved="reloadEvents"
                                     @close="onEventPopupClosed">
                        </event-popup>
                    </div>

                    <div class="vx-col md:w-1/4 w-full pt-5 pr-2 action-dropdown ">
                        <email-action-dropdown v-if="sprint" :data="emailActionData" :to="clientContactMail"
                                               type="sprint-planer" :object-id="$route.params.sprintId"
                                               object-type="App\Models\Project\ProjectSprint"></email-action-dropdown>
                    </div>
                    <div class="vx-col md:w-1/4 w-full pt-5 ">
                        <vs-button color="success" class="w-full" @click="onClickSaveSprint">
                            Speichern
                        </vs-button>
                    </div>
                </vs-row>
            </div>


            <div class="vx-row">
                <div class="vx-col md:w-1/2 w-full">
                    <vs-input v-model="sprint.name" label="Name" type="text" name="name" class="w-full"
                              v-validate="'required'"
                              :class="ClassNameErrorIfEmpty(sprint.project_manager_id)"
                              data-vv-as="Name" :danger="errors.has('name')"></vs-input>
                </div>
            </div>
            <div class="vx-row mt-5">
                <div class="vx-col md:w-1/4 w-full">
                    <vs-input v-model="sprint.start_date" label="Startdatum" type="date" name="start_date"
                              class="w-full"
                              v-validate="'required'" data-vv-as="Startdatum"
                              :danger="errors.has('start_date')"></vs-input>
                </div>
                <div class="vx-col md:w-1/4 w-full">
                    <vs-input v-model="sprint.end_date" label="Enddatum" type="date" name="end_date" class="w-full"
                              v-validate="'required'" data-vv-as="Enddatum" :danger="errors.has('end_date')"></vs-input>

                </div>
            </div>
            <div class="vx-row mt-5">
                <div class="vx-col md:w-1/4 w-full">
                    <vs-select v-model="sprint.client_contact_id" label="Ansprechpartner (Kundenseite)" type="text"
                               :class="ClassNameErrorIfEmpty(sprint.client_contact_id)"
                               name="client_contact_id" class="w-full"
                               v-validate="'required'" data-vv-as="Ansprechpartner (Kundenseite)"
                               :danger="errors.has('client_contact_id')">
                        <vs-select-item v-for="contact in contactList" :key="contact.id" :value="contact.id"
                                        :text="contact.first_name + ' ' + contact.last_name"></vs-select-item>
                    </vs-select>
                </div>
                <div class="vx-col md:w-1/4 w-full">
                    <vs-select v-model="sprint.project_manager_id" label="Projektleiter intern" type="text"
                               :class="ClassNameErrorIfEmpty(sprint.project_manager_id)"
                               name="project_manager_id" class="w-full"
                               v-validate="'required'" data-vv-as="Projektleiter intern"
                               :danger="errors.has('project_manager_id')">
                        <vs-select-item v-for="user in users" :key="user.id" :value="user.id"
                                        :text="user.name "></vs-select-item>
                    </vs-select>
                </div>

            </div>

            <div class="vx-row mt-5">
                <div class="vx-col md:w-1/4 w-full">
                    <vs-input v-model="sprint.resources_needed" label="Eingeplanter Gesamtressourcen (Std.)" type="text"
                              name="resources_needed" class="w-full"></vs-input>
                    <small class="text-red" v-if="sprint.resources_needed < calculatedMinHours">Die eingeplanten
                        Gesamtressourcen sind weniger als die kommunizierten {{ calculatedMinHours }} -
                        {{ calculatedMaxHours }} Std. Aufwand.</small>
                </div>
                <div class="vx-col md:w-1/4 w-full">
                    <vs-input v-model="sprint.revenue" label="Geplante Gesamteinnahmen" type="text"
                              name="revenue" class="w-full"></vs-input>
                    <small class="text-success" v-if=calculatedMinHours > 0"">Hochgerechnet: {{ formatCurrency(calculatedMinHours * defaultHourRate) }} - {{ formatCurrency(calculatedMaxHours * defaultHourRate)}}.<br> Stundensatz:
                        {{ formatCurrency(defaultHourRate) }}</small>
                </div>
            </div>

            <div class="vx-row mt-5">
                <div class="vx-col md:w-1/4 w-full">
                    <label class="vs-input--label" v-if="sprint.ext_board_id">Trello Board:</label>
                    <mg-select v-model="sprint.ext_board_id" :options="projectBoards" name="ext_board_id" class="mt-5"
                               placeholder="Trello Board auswählen"
                               @select="selectedProjectBoard"
                               track-by="id" label="name">
                    </mg-select>
                </div>
                <div class="vx-col md:w-1/4 w-full">
                    <label class="vs-input--label" v-if="sprint.ext_column_id">Spalte/Liste:</label>
                    <mg-select v-model="sprint.ext_column_id" :options="projectBoardLists" name="ext_column_id"
                               class="mt-5"
                               placeholder="Trello Liste auswählen"
                               @select="selectedProjectBoardList"
                               track-by="id" label="name">
                    </mg-select>
                </div>

            </div>

        </vx-card>

        <vx-card title="Weitere Informationen">
            <div class="mt-2" >
                <b>Termine: </b>
                <ul v-if="sprint && sprint.calendar_events && sprint.calendar_events.length >= 1">
                    <li v-for="event in sprint.calendar_events">
                        <span @click="openExistingEvent(event)" class="text-link">{{event.title}} ({{formatDate(event.start_at)}} - {{ formatDate(event.end_at)}})</span>
                    </li>
                </ul>
                <i v-else> Keine Termine gefunden</i>
            </div>

            <div class="mt-2" v-if="sprint">
                <b>Teilnehmer: </b>
                <div v-for="participant in sprint.participants">
                    <a :href="'mailto:' + participant.email">{{participant.name ? participant.name : participant.email}}</a>
                </div>
                <i v-if="sprint.participants.length === 0"> Keine Teilnehmer gefunden</i>
            </div>
        </vx-card>
        <vx-card title="Aufgabenliste">

            <vs-row>
                <vs-col vs-w="6" class="results">

                    <vs-alert color="#de0000" :active="true" icon="warning" class="mb-2 mt-1" v-if="emptyTasks">
                        Keine Aufgaben gefunden, du musst sie zuerst laden.
                    </vs-alert>
                    <div v-if="!emptyTasks">

                        <span class="text">Anzahl der Aufgaben: {{ tasks.length }}</span> <br>
                        <span class="text">In diesem Sprint eingeplante Aufgaben: {{ scheduledTasks }}</span> <br>
                        <span class="text">Anzahl der Arbeitstage: {{ sprint.count_workdays }}</span> <br>
                        <span class="text">Kommunizierter Gesamtaufwand (min): {{
                            calculatedMinHours
                            }} Std. ({{ calculatedMinHours / 8 }} MT)</span><br>
                        <span class="text">Kommunizierter Gesamtaufwand (max): {{
                            calculatedMaxHours
                            }} Std. ({{ calculatedMaxHours / 8 }} MT)</span>

                        <vs-alert color="#de0000" :active="true" icon="warning" class="mb-2 mt-1"
                                  v-if="countTasksWithoutEstimation >= 1">
                            {{ countTasksWithoutEstimation }} eingeplante Aufgaben haben keine Zeitschätzung hinterlegt
                        </vs-alert>
                        <vs-alert color="#de0000" :active="true" icon="warning" class="mb-2 mt-1"
                                  v-if="countEmptyInternalEmployee >= 1">
                            {{ countEmptyInternalEmployee }} Aufgaben sind keinem internen Bearbeiter
                            zugewiesen/gefunden.
                        </vs-alert>
                    </div>

                </vs-col>
            </vs-row>


            <div class="vx-row" v-if="!emptyTasks && tasks.length >= 1">
                <div class="vx-col md:w-1/2 w-full">
                    <vs-input v-model="searchTerm" label="Suchen" type="text" name="searchTerm" class="w-full"
                              v-validate="'required'"
                              data-vv-as="Suchen" :danger="errors.has('searchTerm')"></vs-input>
                </div>
            </div>
            <div class="vx-row mt-5">
                <div class="vx-col md:w-1/2 w-full">

                    <vs-list>
                        <vs-list-item v-for="task in filteredTasks" class="offer-sprint-task-item" :key="task.id">

                            <vs-card>
                                <div class="is-scheduled">
                                    <vs-checkbox v-model="task.scheduled">Eingeplant</vs-checkbox>
                                </div>
                                <div class="cover" v-if="task.cover">
                                    <img :src="task.cover" :alt="task.cover">
                                </div>
                                <div class="labels vs-row">
                                    <span v-for="label in task.labels" :key="label.id" class="label"
                                          :style="{'background-color':label.color_hex}">{{ label.name }}</span>

                                </div>
                                <div class="list-titles vs-row">
                                    <a class="vs-list--title" :href="task.shortUrl" target="_blank">{{ task.name }}</a>
                                    <div class="vs-list--subtitle">
                                        {{ task.description }}
                                    </div>
                                </div>
                                <div class="task-list vs-row">
                                    <span>Aktuelle Liste: {{ task.listName }}</span>
                                </div>
                                <div class="time-estimate">
                                    <span v-if="task.min_hours || task.max_hours">  Zeitschätzung: {{ task.min_hours }} Std. - {{ task.max_hours }} Std.</span>
                                    <span v-else class="text-red">Keine Zeitschätzung hinterlegt</span>

                                </div>
                                <div class="extra-infos vs-row">

                                    <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="4" class="comments"
                                            v-if="task.comments.length">
                                        <feather-icon icon="MessageSquareIcon" class="h-4 w-4 mr-1"></feather-icon>
                                        <span>{{ task.comments.length }}</span>
                                    </vs-col>

                                    <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="4" class="due-date"
                                            v-if="task.due">
                                        <feather-icon icon="CalendarIcon" class="h-4 w-4 mr-1"></feather-icon>
                                        <span>{{ task.due }}</span>
                                    </vs-col>

                                    <vs-col vs-type="flex" vs-justify="left" vs-align="center" vs-w="4" class="member"
                                            v-if="task.members.length">

                                        <ul>
                                            <li v-for="member in task.members" :key="member.id" class="vs-row">
                                                <gravatar-img :email="member.email" v-if="member.email"
                                                              class="h-6 w-6 mr-1 rounded-full shadow-md float-left"></gravatar-img>
                                                <span>{{ member.name }}</span>
                                            </li>
                                        </ul>
                                    </vs-col>


                                </div>
                                <div class="footer vs-row">
                                    <a :href="task.shortUrl" target="_blank">
                                        <vs-button color="warning" type="filled"
                                                   size="small"> In Trello öffnen
                                        </vs-button>
                                    </a>
                                </div>
                            </vs-card>

                        </vs-list-item>
                    </vs-list>

                    <div>

                        <span v-if="sprint.ext_last_update"
                              class="d-block">Letzte Aktualisierung: {{ formatDateTime(sprint.ext_last_update) }}</span>
                        <vs-alert v-else-if="sprint.ext_tasks " color="#de0000" :active="true" icon="warning" class="mb-2 mt-1">
                            Bisher keine Daten-Aktualisierung stattgefunden
                        </vs-alert>

                        <vs-button color="warning" type="filled" size="small" @click="loadTasks" :disabled="!emptyTasks"
                                   class="mr-2">Aufgabenliste von Trello laden
                        </vs-button>
                        <vs-button color="warning" type="filled" size="small" @click="syncTasks" :disabled="emptyTasks"
                                   class="mr-2">Aufgaben syncen
                        </vs-button>
                        <vs-button color="warning" type="border" size="small" @click="deleteTasks" v-if="!emptyTasks">
                            Aufgabenliste leeren
                        </vs-button>

                    </div>

                </div>
            </div>
        </vx-card>
        <vx-card>
            <mail-history :histories="mail_history" :default-opened="false" class="mt-4"></mail-history>
            <history :histories="sprint_history" :default-opened="false" class="mt-4"></history>
        </vx-card>

        <vx-card>
            <div class="vx-row w-full mt-5">
                <div class="vx-col md:w-1/2 ">
                    <vs-button color="danger" size="small" @click="onClickDeleteSprint">
                        Sprint unwiderruflich löschen
                    </vs-button>
                </div>
                <div class="vx-col md:w-1/2">
                    <vs-button color="primary" @click="onClickSaveSprint">
                        Speichern
                    </vs-button>
                </div>
            </div>
        </vx-card>
    </div>

</template>

<script>
import ApiService from "../../../../api";
import moment from "moment";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import * as qs from "qs";
import MgSelect from "@/components/mg-select/MgSelect.vue";
import Gravatar from "vue-gravatar";
import PriceHelper from "@/mixins/helper/price.helper";
import EmailActionDropdown from "@/components/mail-template/email-action-dropdown.vue";
import History from "@/components/history/History.vue";
import MailHistory from "@/components/history/MailHistory.vue";
import EventPopup from "@/views/dashboard/Calendar/components/EventPopup.vue";
import {mapGetters} from "vuex";

export default {
    name: "SprintDetail",

    components: {
        EventPopup,
        MailHistory,
        History,
        EmailActionDropdown,
        MgSelect,
        VuePerfectScrollbar,
        GravatarImg: Gravatar
    },

    data() {
        return {
            sprint: [],
            searchTerm: null,
            isLoading: false,
            contactList: [],
            offer: [],
            employees: [],
            users: [],
            projectBoards: [],
            projectBoardLists: [],
            tasks: [],
            eventPopupActive: false,
            currentEvent: null,
            sprint_history: [],
            mail_history: [],
            categories: [],
            statusList: [
                {
                    id: 1,
                    name: 'Neu'
                },
                {
                    id: 2,
                    name: 'Anfrage versendet'
                },
                {
                    id: 3,
                    name: 'Sprint eingeplant'
                },
                {
                    id: 4,
                    name: 'Sprint abgeschlossen'
                },
                {
                    id: 5,
                    name: 'Kein Sprint notwendig'
                },
            ],
        }
    },

    computed: {
        ...mapGetters(['userId']),
        emailActionData(){
            return {
                sprint: this.sprint,
                offer: this.offer,
            }
        },
        countEmptyInternalEmployee() {
            if (!this.tasks || this.tasks.length == 0) {
                return 0;
            }
            return this.tasks.reduce((acc, task) => {
                if (task.scheduled && task.members.length === 0) {
                    return acc + 1;
                }
                return acc;
            }, 0);

        },
        countTasksWithoutEstimation() {
            if (!this.tasks) {
                return 0;
            }
            return this.tasks.reduce((acc, task) => {
                if (task.scheduled && (!task.min_hours || !task.max_hours)) {
                    return acc + 1;
                }
                return acc;
            }, 0);

        },
        emptyTasks() {
            if (!this.tasks || this.tasks.length == 0) {
                return true;
            }
            return false;
        },
        filteredTasks() {
            if (!this.searchTerm) {
                return this.tasks;
            }

            const term = this.searchTerm.toLowerCase();

            return this.tasks.filter(task => {
                return task.name.toLowerCase().includes(term);
            });
        },
        scheduledTasks() {
            if (!this.tasks) {
                return 0;
            }
            return this.tasks.reduce((acc, task) => {
                if (task.scheduled) {
                    return acc + 1;
                }
                return acc;
            }, 0);
        },
        calculatedMinHours() {
            if (!this.tasks) {
                return 0;
            }
            return this.tasks.reduce((acc, task) => {
                if (task.scheduled) {
                    return acc + task.min_hours;
                }
                return acc;
            }, 0);
        },
        calculatedMaxHours() {
            if (!this.tasks) {
                return 0;
            }
            return this.tasks.reduce((acc, task) => {
                if (task.scheduled) {
                    return acc + task.max_hours;
                }
                return acc;
            }, 0);
        },
        defaultHourRate() {
            if (!this.offer || !this.offer.offer_billing_profil || !this.offer.offer_billing_profil.default_hour_rate) {
                return 0;
            }
            return this.offer.offer_billing_profil.default_hour_rate;
        },
        clientContactMail() {
            if (!this.contactList || this.contactList.length == 0) {
                return null;
            }
            const contact = this.contactList.find(contact => contact.id === this.sprint.client_contact_id);
            if (!contact) {
                return null;
            }
            return contact.email;
        },
        sprintHistoryRequestParams() {
            return {
                order: {
                    'field': 'created_at',
                    'direction': 'DESC'
                }
            }
        }

    },

    created() {
    },

    mounted() {
        this.fetchData();
    },

    methods: {
        fetchData() {
            this.fetchSprintData().then(() => {
                this.fetchUsers();
                this.fetchProjectBoards();
                this.fetchSprintHistory();
                this.fetchMailHistory();
                this.fetchProjectBoardLists(this.sprint.ext_board_id);
                if (this.sprint.ext_tasks != null && this.sprint.ext_tasks.length >= 1) {
                    this.tasks = this.sprint.ext_tasks;
                }

            });
            this.fetchOffer().then(() => {
                this.fetchClientContacts();
            });
            this.fetchCalendarCategories();

        },

        fetchSprintData() {

            return ApiService.get('project/sprint/' + this.$route.params.sprintId).then(response => {
                this.sprint = response.data.data;
            });
        },
        fetchProjectBoards() {

            return ApiService.get('project/sprint/boards').then(response => {
                this.projectBoards = response.data.data;
            });
        },
        fetchProjectBoardLists(boardId) {
            if (!boardId){
                return;
            }
            return ApiService.get('project/sprint/board/' + boardId + '/lists').then(response => {
                this.projectBoardLists = response.data.data;
            });
        },
        fetchProjectBoardListsTasks() {
            if (!this.sprint.ext_column_id) {
                return;
            }

            return ApiService.get('project/sprint/board/list/' + this.sprint.ext_column_id + '/tasks').then(response => {
                this.tasks = response.data.data;
            });
        },
        fetchOffer() {
            return ApiService.get('orders/' + this.$route.params.id).then(response => {
                this.offer = response.data.result;
            });
        }, fetchUsers() {
            let queryParams = {};

            this.$vs.loading()
            ApiService.get('users', {
                params: queryParams,
                paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
            }).then(response => {
                this.users = response.data.result;
                this.$vs.loading.close()
            }).catch(response => {
            })
        },

        fetchCalendarCategories() {

            ApiService.get('categories',{
                params: {
                    filter: [{
                        field: 'usable_on',
                        value: 'calendar'
                    }],
                },
                paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
            }).then(response => {
                this.categories = response.data.result;
            }).catch(response => {
            })

        },
        fetchClientContacts() {
            if (!this.offer || !this.offer.client_id) {
                return;
            }
            let queryParams = {};

            this.$vs.loading()
            ApiService.get('contacts/client/' + this.offer.client_id, {
                params: queryParams,
                paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
            }).then(response => {
                this.contactList = response.data.result;
                this.$vs.loading.close()
            }).catch(response => {
            })
        },
        loadTasks() {
            this.resetTasks();
            this.$vs.loading();
            this.fetchProjectBoardListsTasks().then(() => {
                this.$vs.loading.close();
            });
        },
        resetTasks() {
            this.sprint.ext_tasks = null;
            this.tasks = null;
            return true;
        },
        syncTasks() {
            this.$vs.loading();
            ApiService.put('project/sprint/' + this.$route.params.sprintId + '/sync-tasks').then(response => {
                this.fetchData();
                this.$vs.loading.close();
                this.$vs.notify({
                    title: 'Erfolgreich',
                    text: 'Die Aufgaben wurden aktualisiert',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                });
            }).catch(response => {
                this.$vs.loading.close();
                this.$vs.notify({
                    title: 'Fehler',
                    text: response.message,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
            })
        },
        selectedProjectBoard(board) {
            if (board.id === this.sprint.ext_board_id) {
                return;
            }
            this.sprint.ext_board_id = board.id;
            this.sprint.ext_column_id = null;
            this.fetchProjectBoardLists(board.id);
        },
        selectedProjectBoardList(list) {
            this.sprint.ext_column_id = list.id;
        },
        formatDate(date) {
            return moment(date).format('DD.MM.YYYY');
        },
        formatDateTime(date) {
            return moment(date).format('DD.MM.YYYY HH:mm:ss');
        },
        ClassNameErrorIfEmpty(value) {
            if (!value) {
                return 'vs-input--danger';
            }
            return '';
        },
        deleteTasks() {
            this.resetTasks();
            this.$vs.notify({
                title: 'Erfolgreich',
                text: 'Die Aufgaben wurden geleert, du musst aber noch speichern.',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: 'success'
            });
        },
        saveTasks() {
            this.$vs.loading()

            const tasks = this.tasks;

            const sprint = {
                ext_tasks: tasks
            }

            ApiService.put('project/sprint/' + this.$route.params.sprintId, sprint).then(response => {
                this.$vs.loading.close();
                this.$vs.notify({
                    title: 'Erfolgreich',
                    text: 'Die Aufgaben wurden gespeichert',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                });
            }).catch(response => {
                this.$vs.loading.close();
                this.$vs.notify({
                    title: 'Fehler',
                    text: response.message,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
            })
        },
        onClickSaveSprint() {

            this.$vs.loading()
            const sprint = JSON.parse(JSON.stringify(this.sprint));
            if (this.tasks.length >= 1) {
                sprint.ext_tasks = null;
                sprint.ext_tasks = this.tasks;
            }


            ApiService.put('project/sprint/' + this.$route.params.sprintId, sprint).then(response => {
                this.updatedData();
                this.$vs.loading.close();
                this.$vs.notify({
                    title: 'Erfolgreich',
                    text: 'Der Sprint wurde gespeichert',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                });
            }).catch(response => {
                this.$vs.loading.close();
                this.$vs.notify({
                    title: 'Fehler',
                    text: response.message,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
            })
        },
        onClickDeleteSprint() {
            ApiService.delete('project/sprint/' + this.$route.params.sprintId).then(response => {
                this.$router.push({name: 'offer-sprint-planer', params: {id: this.$route.params.id}})
                this.updatedData();
                this.$vs.notify({
                    title: 'Erfolgreich',
                    text: 'Der Sprint wurde gelöscht',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'success'
                });
            }).catch(response => {
                this.$vs.notify({
                    title: 'Fehler',
                    text: response.message,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
            })
        },
        updatedData() {
            this.fetchData();
            this.$emit('updateData')
        },
        formatCurrency(number) {
            return PriceHelper.formatCurrency(number);
        },
        fetchSprintHistory() {
            const id = this.$route.params.sprintId;
            ApiService.get(`project/sprint/history/${id}`, {
                params: this.sprintHistoryRequestParams,
                paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
            }).then(response => {
                if (response.status !== 200) {
                    return;
                }
                this.sprint_history = response.data.result.result;
            });
        },
        fetchMailHistory() {
            const body = {
                object_id: this.$route.params.sprintId,
                object_name: 'App\\Models\\Project\\ProjectSprint'
            }
            ApiService.post(`mail-log`, body).then(response => {
                if (response.status !== 200) {
                    return;
                }
                this.mail_history = response.data.result;
            });
        },
        createEmptyEventData(start, end, employees, title, description) {
            let category = null;
            const catId = 30;

            if(catId) {
                category = this.categories.find(item => item.id === catId);
            }

            let firstEmployee = employees[Object.keys(employees)[0]]
            const startAt = new moment(start).set("hour", 0).set("minute", 0).set("second", 0).format('YYYY-MM-DD HH:mm:ss')
            const endAt = new moment(end).set("hour", 0).set("minute", 0).set("second", 0).format('YYYY-MM-DD HH:mm:ss')

            const employeesIds = [];
            for (const [key, value] of Object.entries(employees)) {
                employeesIds.push(value.id);
            }

            let emptyEvent = {
                title: "Sprint: " + title,
                description: description,
                content: description,
                url: '/offers/'+ this.$route.params.id +'/sprint-planer/'+ this.$route.params.sprintId,
                start_at: startAt,
                end_at: endAt,
                is_whole_day: true,
                reminder_count: 0,
                reminder_interval: 0,
                reminder_unit: 'd',
                color: '1abc9c',
                user_id: firstEmployee.id,
                users: employeesIds,
                creator_user_id: this.userId,
                recurring_event: false,
                recurring_config: null,
                category: category
            }
            console.log(emptyEvent)
            return emptyEvent;
        },

        onAddEvent() {
            this.currentEvent = this.createEmptyEventData(this.sprint.start_date, this.sprint.end_date, this.sprint.internal_participants, this.sprint.name + ' - '+ this.offer.client.company_name, this.sprint.description);
            this.eventPopupActive = true;
        },

        reloadEvents() {
            this.fetchSprintData();
        },
        onEventPopupClosed() {
            this.eventPopupActive = false;
        },
        openExistingEvent(event) {
            this.currentEvent = event;
            this.eventPopupActive = true;
        }
    },


}
</script>

<style lang="scss">
.offer-sprint-task-item {
  border-bottom: 0;

  > .vs-list--slot {
    margin: 0;
    width: 100%;

    > .con-vs-card {
      margin-bottom: 0px;

      &:hover {
        box-shadow: 0 4px 25px 0 rgba(0, 0, 0, .3);
      }

      .cover img {
        width: auto;
        max-width: 100%;
      }

      .cover {
        width: auto;
      }

      .vs-list--title {
        color: black;
        font-size: 14px;
      }

      .labels span.label {
        padding: 4px 11px;
        border-radius: 5px;
      }

      .is-scheduled {
        position: absolute;
        right: 0;
        margin-right: 11px;
      }
    }

  }


}
.action-button {
    position: absolute;
    right: 0px;
    margin-top: -70px;
    width: 750px;
}
.text-link {
    text-decoration: underline;
    cursor: pointer;
}

.action-dropdown button {
    height: 39px;
    padding-top: 13px!important;
    width: 100%;
    margin-top: 1px;
    .vs-icon {position: absolute;right: 10px;top: 13px;}

}
.vs-input--danger .input-select-con input {
    border-color: red;
}

</style>