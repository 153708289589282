<template>
    <div class="event-color-picker">
        <template v-for="(color, index) in colors">
            <input class="event-color-picker__field" type="radio" v-model="selected" name="event_color" :id="`event_color_${index}`" :value="color" @change="onChange">
            <label class="event-color-picker__label" :for="`event_color_${index}`" :style="`background-color:#${color}`"></label>
        </template>
    </div>
</template>

<script>
    export default {
        name: "EventColorPicker",
        props: {
            value: {
                type: String,
                default: 'e24733'
            }
        },
        mounted() {
            this.selected = this.value
        },
        data() {
            return {
                selected: '1abc9c',
                colors: ['1abc9c', '3498db', '2c50c7', '19843d', '75c64b', 'c650ba', '8e44ad', 'e2bd00', 'e67e22', 'e24733', '6f0e12', '7f8c8d', '34495e']
            }
        },
        methods: {
            onChange(val) {
               this.$emit('input', this.selected);
            }
        },
        watch: {
            value(val) {
                this.selected = val;
            }
        }
    }
</script>

<style lang="scss">
.event-color-picker {
    padding: .5rem .5rem 0 .5rem;
    display: flex;
    flex-wrap: wrap;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, .2);
    margin: 2px 0;
}

.event-color-picker__label {
    display: inline-block;
    width: 23px;
    height: 23px;
    border-radius: 3px;
    margin-right: 10px;
    cursor: pointer;
    opacity: .9;
    margin-bottom: .5rem;
    &:hover {
        box-shadow: 0 0 0 3px rgba(0,0,0,.1);
    }
}

.event-color-picker__field {
    display: none;
}
.event-color-picker__field:checked + .event-color-picker__label {
    opacity: 1;
    box-shadow: inset 0 0 0 2px rgba(0,0,0,.4), 0 0 0 4px rgba(0,0,0,.1);
    &:hover {
        box-shadow: inset 0 0 0 2px rgba(0,0,0,.4), 0 0 0 4px rgba(0,0,0,.1);
    }
}
</style>