import moment from 'moment';

const Recurring = {

    getString(event) {
        if (event.recurring_config.interval_typ === 'daily') {
            return this.getDailyString(event)
        }

        if (event.recurring_config.interval_typ === 'weekly') {
            return this.getWeeklyString(event)
        }

        if (event.recurring_config.interval_typ === 'monthly') {
            return this.getMonthlyString(event)
        }

        if (event.recurring_config.interval_typ === 'yearly') {
            return this.getYearlyString(event)
        }

        return '';
    },

    getDailyString(event) {
        const config = event.recurring_config,
            type = this.getType(config.interval_typ),
            wholeDay = event.is_whole_day;

        let string = '';

        if (config.interval === 1) {
            string += 'Jeden Tag, ';
        } else {
            string += `Alle ${config.interval} Tage, `;
        }

        return this.buildTimeString(string, wholeDay, event, config);
    },

    getWeeklyString(event) {
        const config = event.recurring_config,
            wholeDay = event.is_whole_day;

        let string = '';

        const days = this.buildDayString(config.week_day);

        if (config.interval === 1) {
            string += `Jeden ${days}, `;
        } else {
            string += `Alle ${config.interval} Wochen, jeden ${days}, `;
        }

        return this.buildTimeString(string, wholeDay, event, config);
    },

    getMonthlyString(event) {
        const config = event.recurring_config,
            wholeDay = event.is_whole_day;

        let string = '';

        if (config.week_type === 1) {
            if (config.interval === 1) {
                string += `Am ${config.day}. jeden Monats, `;
            } else {
                string += `Am ${config.day}. alle ${config.interval} Monate, `;
            }
        } else {
            const day = this.getDay(config.week_day),
                weekInterval = this.getMonthlyInterval(config.week);

            if (config.interval === 1) {
                string += `Am ${weekInterval.label.toLowerCase()} ${day.label} jeden Monat, `;
            } else {
                string += `Am ${weekInterval.label.toLowerCase()} ${day.label} alle ${config.interval} Monate, `;
            }
        }

        return this.buildTimeString(string, wholeDay, event, config);
    },

    getYearlyString(event) {
        const config = event.recurring_config,
            wholeDay = event.is_whole_day,
            month = this.getMonth(config.month);

        let string = '';

        if (config.week_type === 1) {
            if (config.interval === 1) {
                string += `Jedes Jahr am ${config.day}. ${month.label}, `;
            } else {
                string += `Alle ${config.interval} Jahre am ${config.day}. ${month.label}, `;
            }
        } else {
            const day = this.getDay(config.week_day),
                weekInterval = this.getMonthlyInterval(config.week);

            if (config.interval === 1) {
                string += `Jedes Jahr am ${weekInterval.label.toLowerCase()} ${day.label} im ${month.label}, `;
            } else {
                string += `Alle ${config.interval} Jahre am ${weekInterval.label.toLowerCase()} ${day.label} im ${month.label}, `;
            }
        }

        return this.buildTimeString(string, wholeDay, event, config);
    },

    buildTimeString(string, wholeDay, event, config) {
        string += `gültig bis ${moment(config.until).format('DD.MM.YYYY')}`;

        if (!wholeDay) {
            const start = moment(event.start_at).format('HH:mm');
            const end = moment(event.end_at).format('HH:mm');

            string += ` von ${start} bis ${end}.`;
        } else {
            string += '.';
        }

        return string;
    },

    buildDayString(dayIds) {
        const days = this.options.dayOptions.filter(item => dayIds.indexOf(item.id) !== -1),
            count = days.length;

        let string = '';

        for (let i = 0; i < count; i++) {
            if (i === count - 1 && count > 1) {
                string += `${days[i].label} und `
            } else if (i === count - 1) {
                string += days[i].label
            } else {
                string += `${days[i].label}, `
            }
        }

        return string;
    },

    getType(id) {
        return this.options.recurringTypes.find(item => item.id === id);
    },

    getDay(id) {
        return this.options.dayOptions.find(item => item.id === id);
    },

    getMonthlyInterval(id) {
        return this.options.monthlyIntervalOptions.find(item => item.id === id);
    },

    getMonth(id) {
        return this.options.monthOptions.find(item => item.id === id);
    },

    options: {
        recurringTypes: [
            {id: 'daily', 'label': 'Täglich', option_name: 'Tag(e)'},
            {id: 'weekly', 'label': 'Wöchentlich', option_name: 'Woche(n)'},
            {id: 'monthly', 'label': 'Montalich', option_name: 'Monat(e)'},
            {id: 'yearly', 'label': 'Jährlich', option_name: 'Jahr(e)'}
        ],
        typeOptions: [
            {id: 1, 'label': 1},
            {id: 2, 'label': 2},
            {id: 3, 'label': 3},
            {id: 4, 'label': 4},
            {id: 5, 'label': 5},
        ],
        dayOptions: [
            {id: 1, label: 'Montag', short: 'Mo'},
            {id: 2, label: 'Dienstag', short: 'Di'},
            {id: 3, label: 'Mittwoch', short: 'Mi'},
            {id: 4, label: 'Donnerstag', short: 'Do'},
            {id: 5, label: 'Freitag', short: 'Fr'},
            {id: 6, label: 'Samstag', short: 'Sa'},
            {id: 7, label: 'Sonntag', short: 'So'},
        ],
        monthOptions: [
            {id: 1, label: 'Januar'},
            {id: 2, label: 'Februar'},
            {id: 3, label: 'März'},
            {id: 4, label: 'April'},
            {id: 5, label: 'Mai'},
            {id: 6, label: 'Juni'},
            {id: 7, label: 'Juli'},
            {id: 8, label: 'August'},
            {id: 9, label: 'September'},
            {id: 10, label: 'Oktober'},
            {id: 11, label: 'November'},
            {id: 12, label: 'Dezember'},
        ],
        monthlyIntervalOptions: [
            {id: 1, label: 'Ersten'},
            {id: 2, label: 'Zweiten'},
            {id: 3, label: 'Dritten'},
            {id: 4, label: 'Vierten'},
            {id: 5, label: 'Letzten'},
        ],
    }
};

export default Recurring;