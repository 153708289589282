<template>
    <div>
        <div class="history-item">
            <div class="history-item__type"><div class="mg-label success">E-Mail Nachricht</div></div>
            <div class="history-item__date">{{history.created_at | date}}</div>
            <div class="history-item__description" v-html="history.subject">{{history.subject}}</div>
            <div class="history-item__user">
                Empfänger: {{extractKeys(history.to)}}
            <span v-if="history.cc && history.cc != null && history.cc != 'null'">(CC: {{extractKeys(history.cc)}})</span>
            <span v-if="history.bcc && history.bcc != null && history.bcc != 'null'">(BCC: {{extractKeys(history.bcc)}})</span>
            </div>
            <div class="history-item__user">Von: {{history.from}} </div>
            <div class="action-button">
                <vs-button @click="active = true" color="primary" type="filled" size="small">E-Mail öffnen</vs-button>
            </div>
        </div>
        <vs-popup classContent="mail-message-modal--popup scrollable" class="mail-message-modal--popup" :title="history.subject" @close="onClose"
                  :active="visible" >
            <div class="mail-message-modal">


                <vs-row class="stripe pt-2 pb-2">
                    <div class="vx-col md:w-2/12 w-full  ">Von:</div>
                    <div class="vx-col md:w-4/12 w-full ">{{history.from}}</div>
                    <div class="vx-col md:w-2/12 w-full  pl-2">Versendet:</div>
                    <div class="vx-col md:w-4/12 w-full  ">{{history.created_at | date}}</div>
                </vs-row>
                <vs-row  class=" pt-2 pb-2">
                    <div class="vx-col md:w-2/12 w-full">An:</div>
                    <div class="vx-col md:w-4/12 w-full">{{extractKeys(history.to)}}</div>
                    <div class="vx-col md:w-2/12 w-full pl-2">CC:</div>
                    <div class="vx-col md:w-4/12 w-full" v-if="history.cc && history.cc != null && history.cc != 'null'">{{extractKeys(history.cc)}}</div>
                    <div class="vx-col md:w-4/12 w-full" v-else>-</div>
                </vs-row>

                <vs-row  class="stripe pt-2 pb-2" v-if="history.bcc && history.bcc != null && history.bcc != 'null'">
                    <div class="vx-col md:w-2/12 w-full">BCC:</div>
                    <div class="vx-col md:w-4/12 w-full" >{{extractKeys(history.bcc)}}</div>
                </vs-row>

                <vs-divider/>

                <div class="mail-message-modal__body__message" v-html="history.content"></div>

            </div>
        </vs-popup>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: "HistoryItem",
        props: {
            history: {
                type: Object || Array
            }
        },
        data() {
            return {
                active: false,
            }
        },
        computed: {
            visible() {
                return this.active;
            },
        },
        methods: {
            onClose() {
                this.active = false;
            },
            extractKeys(jsonString) {
                try {
                    const toObject = JSON.parse(jsonString);
                    const keys = Object.keys(toObject);
                    const keysString = keys.join(';');
                    return keysString;
                } catch (error) {
                    console.error('Fehler beim Parsen des JSON-Strings:', error);
                }
            }
        },
        filters: {
            date: function (value) {
                return moment(value).format('DD.MM.YYYY HH:mm') + ' Uhr';
            }
        }
    }
</script>

<style>
.vs-row.stripe {
    background: #ebe9e9;
}
.mail-message-modal--popup .vs-popup {
    width: 1000px;
}
.history-item {
    .action-button {
       margin-right: 10px;
        margin-top: 70px;

    }
}

</style>