<template>
    <vx-card title="Neuer Sprint erstellen" ref="card">


        <div class="vx-col lg:w-full w-full">
            <div class="vx-row">
                <div class="vx-col lg:w-1/3">
                    <vs-input class="w-full" label="Name des Sprints" name="supplier_number"
                              v-model="sprint.name" type="text"/>
                </div>
                <div class="vx-col lg:w-2/3">
                    <vs-button color="primary" @click="onClickNew" class="mt-5">Sprint erstellen</vs-button>
                </div>
            </div>
        </div>


    </vx-card>
</template>

<script>
import ApiService from "../../../../api";
import moment from "moment";
import {mapGetters} from "vuex";

export default {
    name: "CreateSprint",

    components: {},

    data() {
        return {
            sprint: {
                "name": "Monat / Jahr",
                "status": 1,
                "creator_user_id": null,
                "offer_id": null,
            },
        }
    },

    computed: {
        ...mapGetters(['userHasPermission', 'userId', 'user']),

    },

    created() {
        this.setInitialData()
    },

    mounted() {
    },

    methods: {
        setInitialData() {
            this.sprint.creator_user_id = this.userId;
            this.sprint.offer_id = this.$route.params.id;
        },
        onClickNew() {
            ApiService.post(`project/sprint`, this.sprint).then(response => {
                this.$router.push({name: 'offer-sprint-planer-detail', params: {id: this.$route.params.id, sprintId: response.data.data.id}})
            }).catch(response => {
                this.$vs.notify({
                    title: 'Fehler',
                    text: response.message,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
            })
        },
    },


}
</script>

<style scoped>

</style>