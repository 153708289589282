<template>
    <vs-popup id="event-modal" :title="title" :active="visible" @close="onClickClose" class="event-modal"
              :class="{'vs-popup--colored-header':!editMode,' is--extended':extendedMode}" :style="popupStyle">

        <template v-if="data && editMode">

            <div class="flex event-form">
                <div class="event-form-col">

                    <div class="flex mb-2 -mt-2 text-blue-600" v-if="isEdit && data.recurring_event">
                        <feather-icon icon="RepeatIcon" svgClasses="w-5 h-5 mr-2"/>
                        <p>{{recurringString}}</p>
                    </div>

                    <vs-input v-model="data.title" label="Titel" class="w-full mb-2"
                              placeholder="Titel eintragen..." :danger="eventErrors.includes('title')"></vs-input>

                    <a :href="data.url" target="_blank" v-if="data.url" class="text-blue-600 underline mb-2 block">Link zum Ereignis</a>
                    <vs-textarea v-model="data.description" label="Kurzbeschreibung" class="w-full mb-1"
                                 placeholder="Kurzbeschreibung eintragen..."></vs-textarea>

                    <label class="vs-input--label">Kategorie</label>
                    <category-selector v-model="data.category" :multiple="false" type="calendar"
                                       placeholder="Kategorie wählen..." class="mb-2"></category-selector>

                    <label class="vs-input--label block">Farbe</label>

                    <event-color-picker class="mb-8" v-model="data.color"></event-color-picker>

                    <div class="relative">

                        <vs-checkbox v-model="data.is_whole_day" class="mb-4 ml-0">Ganztägiges Ereignis</vs-checkbox>
                        <div class="flex mb-2 items-center">
                            <div class="date-picker w-1/2 pr-2"
                                 :class="{'is--danger': eventErrors.includes('start_at')}">
                                <label class="vs-input--label block">Start</label>
                                <flat-pickr :key="startPickerKey" v-model="data.start_at"
                                            :config="datePickerStartConfig"
                                            class="w-full"></flat-pickr>
                            </div>
                            <div class="date-picker w-1/2 pl-2" :class="{'is--danger':eventErrors.includes('end_at')}"
                                 v-show="!data.recurring_event || !data.is_whole_day">
                                <label class="vs-input--label block">Ende</label>
                                <flat-pickr :key="endPickerKey" v-model="data.end_at" :config="datePickerEndConfig"
                                            class="w-full"></flat-pickr>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="event-form-col" v-if="extendedMode">

                    <template v-if="!isEdit">
                        <vs-checkbox v-model="data.recurring_event" class="mb-4 ml-0">Wiederholendes Ereignis
                        </vs-checkbox>
                        <recurring-event-from v-if="data.recurring_event" :event="data" class="mb-5 pb-5"
                                              @update="onChangeRecurringData"></recurring-event-from>
                    </template>

                    <label class="vs-input--label mt-8">Mitarbeiter Zuweisung</label>
                    <user-selector :multiple="true" class="mt-1" v-model="data.users"></user-selector>
                    <p class="text-sm pt-1 text-blue-600">Kein Mitarbeiter auswählen wenn es ein Ereignis für alle
                        ist</p>


                    <template v-if="isEdit && isRecurring">
                        <vs-checkbox v-model="editAll" class="mt-4 ml-0"> Änderung für alle in der Serie vornehmen
                        </vs-checkbox>
                    </template>

                    <template v-if="isEdit && canEditEvent && (isRecurring || hasMultipleUsers)">
                        <div class="flex mt-8 bg-gray-100 p-2 rounded border border-gray-300">
                            <div v-if="hasMultipleUsers" class="w-1/2">
                                <vs-button color="danger" v-show="!showHardDelete"
                                           @click="timeOutReset('showHardDelete')"
                                           class="w-full">Ereignis löschen
                                </vs-button>
                                <vs-button color="danger" v-show="showHardDelete" @click="deleteEvent" class="w-full">
                                    Sicher?
                                </vs-button>
                            </div>
                            <div v-if="isRecurring" class="w-1/2">
                                <vs-button color="danger" v-show="!showRecurringDelete"
                                           @click="timeOutReset('showRecurringDelete')" class="w-full">Serie löschen
                                </vs-button>
                                <vs-button color="danger" v-show="showRecurringDelete" @click="deleteRecurring"
                                           class="w-full">Sicher?
                                </vs-button>
                            </div>
                        </div>
                    </template>
                </div>
            </div>

            <div class="flex justify-end mt-6">
                <vs-button color="primary" type="border" @click="onClickExtended">
                    <span v-if="!extendedMode">Erweitert</span>
                    <span v-if="extendedMode">Einfach</span>
                </vs-button>
                <vs-button color="danger" type="border" class="ml-auto" @click="onClickClose">Abbrechen</vs-button>
                <vs-button color="success" class="ml-4" @click="onSave">Speichern</vs-button>
            </div>

        </template>

        <template v-if="data && !editMode">
            <div class="flex mb-2 -mt-2 text-blue-600" v-if="data.recurring_event">
                <feather-icon icon="RepeatIcon" svgClasses="w-5 h-5 mr-2"/>
                <p>{{recurringString}}</p>
            </div>
            <table class="mb-4">
                <tr>
                    <td class="font-bold pr-4">Ganztägig:</td>
                    <td v-if="data.is_whole_day">Ja</td>
                    <td v-else>Nein</td>
                </tr>
                <tr>
                    <td class="font-bold pr-4">Zeitpunkt:</td>
                    <td v-if="data.is_whole_day">
                        <span>{{data.start_at|date}}</span>
                        <span v-if="data.end_at"> - {{data.end_at|date}}</span>
                    </td>
                    <td v-else>
                        <span>{{data.start_at|dateTime}}</span>
                        <span v-if="data.end_at"> - {{data.end_at|dateTime}}</span>
                    </td>
                </tr>
                <tr v-if="data.category">
                    <td class="font-bold pr-4">Kategorie:</td>
                    <td>{{data.category.name}}</td>
                </tr>
                <tr v-if="data.users !== null && data.users.length > 0">
                    <td class="font-bold pr-4 pt-2 align-top">Teilnehmer:</td>
                    <td>
                        <div class="flex flex-wrap -ml-3">
                            <template v-for="(user, index) in data.users">
                           <span>
                                 <gravatar-img :email="user.email" class="rounded-full relative top-2 mr-1 ml-4"
                                               width="24" height="24"/>
                                {{user.detail.first_name}} {{user.detail.last_name}}
                           </span>
                            </template>
                        </div>
                    </td>
                </tr>
                <tr v-if="data.users === null || data.users.length === 0">
                    <td class="font-bold pr-4">Teilnehmer:</td>
                    <td>Alle Mitarbeiter</td>
                </tr>
            </table>
            <template v-if="data.description">
                <p class="block font-bold mb-2">Kurzbeschreibung:</p>
                <div v-html="data.description" style="white-space: pre-wrap;"></div>
            </template>
            <div class="flex justify-end mt-6">
                <vs-button color="danger" v-if="(!canEditEvent || hasMultipleUsers) && isAssigned" v-show="!showDelete"
                           @click="timeOutReset('showDelete')">Von meinem Kalender entfernen
                </vs-button>
                <vs-button color="danger" v-if="!canEditEvent || hasMultipleUsers" v-show="showDelete"
                           @click="deleteEventAssignment">Sicher?
                </vs-button>

                <vs-button color="danger" v-if="canEditEvent && !hasMultipleUsers" v-show="!showDelete"
                           @click="timeOutReset('showDelete')">Unwiderruflich Löschen
                </vs-button>
                <vs-button color="danger" v-if="canEditEvent && !hasMultipleUsers" v-show="showDelete"
                           @click="deleteEvent">Sicher?
                </vs-button>

                <vs-button color="success" class="ml-auto" @click="editMode=true" v-if="canEditEvent">Bearbeiten
                </vs-button>

            </div>
        </template>
    </vs-popup>
</template>

<script>
    import {mapGetters} from "vuex";
    import cloneDeep from "lodash/cloneDeep";
    import FlatPickr from "vue-flatpickr-component/src/component";
    // localization is optional
    import {German} from 'flatpickr/dist/l10n/de.js';
    import CategorySelector from "../../../../components/search/CategorySelector";
    import EventColorPicker from "./EventColorPicker";
    import ApiService from "../../../../api";
    import moment from 'moment';
    import UserSelector from "../../../../components/search/UserSelector";
    import RecurringEventFrom from "./RecurringEventFrom";
    import Recurring from "./recurring";
    import GravatarImg from "vue-gravatar/src/components/GravatarImg";

    export default {
        name: "EventPopup",
        components: {GravatarImg, RecurringEventFrom, UserSelector, EventColorPicker, CategorySelector, FlatPickr},
        props: {
            active: {
                type: Boolean,
                default: false
            },
            event: {
                type: Object,
                default: () => {
                    return {};
                }
            },
        },
        data() {
            return {
                editAll: false,
                data: null,
                startPickerKey: 1,
                endPickerKey: 1,
                eventErrors: [],
                editMode: true,
                showDelete: false,
                extendedMode: false,
                showRecurringDelete: false,
                showHardDelete: false
            }
        },
        created() {
            if (this.active) {
                this.showDelete = false;
                this.showRecurringDelete = false;
                this.showHardDelete = false;
                this.extendedMode = false;
                this.editAll = true;
                this.data = cloneDeep(this.event);

                if (this.data.id !== null && this.data.id !== undefined) {
                    this.editMode = false;
                    this.extendedMode = false;

                    if (this.data.recurring_hash !== null) {
                        this.data.recurring_event = true;
                    }
                } else if (data.event.extendedProps.rawData.special_event && data.event.extendedProps.rawData.special_event === true) {
                  this.editMode = false;
                  this.extendedMode = false;
                } else {
                    this.editMode = true;
                    this.extendedMode = false;
                }
            }
        },
        computed: {
            ...mapGetters(['userId']),
            title() {
                if (this.data !== null && this.data.id !== null && this.data.id !== undefined) {
                    if (this.editMode) {
                        return 'Ereigniss bearbeiten';
                    }
                    return this.data.title;
                }
                return 'Ereigniss erstellen';
            },
            visible() {
                return this.active;
            },
            isEdit() {
                return this.data !== null && this.data.id !== null && this.data.id !== undefined && this.editMode;
            },
            canEditEvent() {

              return !(this.data.special_event && this.data.special_event === true);

                /*
                if (this.data.users.length === 1) {
                    if (this.data.users[0].id === this.userId) {
                        return true;
                    }
                }

                if (this.data.creator_user_id === this.userId) {
                    return true;
                }

                return false;
                */
            },
            hasMultipleUsers() {
                return this.event.users.length > 1;
            },
            isAssigned() {
              if (this.data.special_event && this.data.special_event === true) {
                return false;
              }

                const find = this.event.users.find(user => user.id === this.userId);
                return find !== null && find !== undefined;
            },
            isRecurring() {
                return this.event.recurring_hash !== null;
            },
            popupStyle() {
                if (!this.editMode) {
                    return 'color:#' + this.data.color;
                }

                return null;
            },
            datePickerStartConfig() {
                if (this.data.is_whole_day) {
                    return {
                        altFormat: 'd.m.Y',
                        dateFormat: 'Y-m-d H:i:s',
                        altInput: true,
                        allowInput: true,
                        enableTime: false,
                        locale: German
                    };
                }

                return {
                    altFormat: 'd.m.Y H:i',
                    dateFormat: 'Y-m-d H:i:s',
                    altInput: true,
                    allowInput: true,
                    enableTime: true,
                    locale: German
                };
            },

            datePickerEndConfig() {
                if (this.data.is_whole_day) {
                    return {
                        altFormat: 'd.m.Y',
                        dateFormat: 'Y-m-d H:i:s',
                        altInput: true,
                        allowInput: true,
                        enableTime: false,
                        locale: German,
                        minDate: moment(this.data.start_at).add(1, 'day').format('YYYY-MM-DD')
                    };
                }


                return {
                    altFormat: 'd.m.Y H:i',
                    dateFormat: 'Y-m-d H:i:s',
                    altInput: true,
                    allowInput: true,
                    enableTime: true,
                    locale: German,
                    minDate: moment(this.data.start_at).format('YYYY-MM-DD HH:II'),
                    maxDate: moment(this.data.start_at).set("hour", 23).set("minute", 59).format('YYYY-MM-DD HH:mm')
                };
            },

            recurringString() {
                if (!this.data.recurring_event) {
                    return '';
                }

                return Recurring.getString(this.data);
            }
        },
        methods: {
            timeOutReset(attr) {
                this[attr] = true;

                setTimeout(() => {
                    this[attr] = false;
                }, 2000)
            },
            onClickExtended() {
                if (this.extendedMode) {
                    const originalClone = cloneDeep(this.event);
                    this.extendedMode = false;
                    this.data.users = originalClone.users;
                    this.editAll = true;

                    return;
                }

                this.extendedMode = true;
            },
            validateForm() {
                this.eventErrors = [];
                if (this.data.title === null || this.data.title === '' || this.data.title === ' ') {
                    this.eventErrors.push('title');
                }

                if (this.data.start_at === null || this.data.start_at === '' || !moment(this.data.start_at).isValid()) {
                    this.eventErrors.push('start_at');
                }

                if (!this.data.is_whole_day) {
                    if (this.data.end_at === null || this.data.end_at === '' || !moment(this.data.end_at).isValid() || moment(this.data.end_at).diff(this.data.start_at, 'minutes') < 1) {
                        this.eventErrors.push('end_at');
                    }
                } else if (this.data.end_at !== null && this.data.end_at !== undefined && this.data.end_at.length > 1) {
                    if (!moment(this.data.end_at).isValid() || moment(this.data.end_at).diff(this.data.start_at, 'days') < 1) {
                        this.eventErrors.push('end_at');
                    }
                }

                if (this.eventErrors.length > 0) {
                    return false;
                }

                return true;
            },
            onClickClose() {
                this.closePopup();
            },
            closePopup() {
                this.$emit('close');
                this.$emit('update:active', false)
            },

            onSave() {
                const requestData = cloneDeep(this.data);

                if (this.validateForm()) {
                    if (requestData.end_at) {
                        requestData.start_at = moment(requestData.start_at).format('YYYY-MM-DD HH:mm:ss');
                        requestData.end_at = moment(requestData.end_at).format('YYYY-MM-DD HH:mm:ss');
                    } else {
                        requestData.start_at = moment(requestData.start_at).format('YYYY-MM-DD HH:mm:ss');
                        requestData.end_at = null;
                    }

                    if (this.data.id !== undefined && this.data !== null) {
                        this.updateEvent(requestData);
                        return
                    }

                    this.createEvent(requestData);
                }
            },

            updateEvent(requestData) {
                this.$vs.loading();

                let editAllQuery = '';

                if (this.editAll && this.data.recurring_event) {
                    editAllQuery = '?recurring=1'
                }

                ApiService.put('calendar-events/' + requestData.id + editAllQuery, requestData).then((response) => {
                    this.$vs.loading.close()
                    if (response.data.status === 'success') {
                        this.$vs.notify({
                            title: 'Das Ereignis wurde gespeichert.',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        });
                        this.$emit('saved');
                        this.closePopup();
                        return;

                    } else {
                        return this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: response.data.message,
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }
                }).catch((error) => {
                    this.$vs.loading.close()
                    return this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: error.message,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                });
            },
            deleteEventAssignment() {
                this.deleteEvent('?remove_assignment=1');
            },
            deleteRecurring() {
                this.deleteEvent('?recurring=1');
            },
            deleteEvent(queryParam) {
                this.$vs.loading();

                if (typeof queryParam !== 'string') {
                    queryParam = '';
                }

                ApiService.delete('calendar-events/' + this.data.id + queryParam).then((response) => {
                    this.$vs.loading.close()
                    if (response.data.status === 'success') {
                        this.$vs.notify({
                            title: 'Das Ereignis wurde gelöscht',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        });
                        this.showDelete = false;
                        this.$emit('saved');
                        this.closePopup();
                        return;
                    } else {
                        this.showDelete = false;
                        return this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: response.data.message,
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }
                }).catch((error) => {
                    this.$vs.loading.close()
                    this.showDelete = false;
                    return this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: error.message,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                });
            },

            createEvent(requestData) {
                this.$vs.loading();

                ApiService.post('calendar-events', requestData).then((response) => {
                    this.$vs.loading.close()
                    if (response.data.status === 'success') {
                        this.$vs.notify({
                            title: 'Das Ereignis wurde erfolgreich erstellt.',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        });
                        this.$emit('saved');
                        this.closePopup();
                        return;

                    } else {
                        return this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: response.data.message,
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    }
                }).catch((error) => {
                    this.$vs.loading.close()
                    return this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: error.message,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                });
            },

            onChangeRecurringData(recurring_config) {
                this.data.recurring_config = recurring_config;
            }
        },

        watch: {
            active(value) {
                if (this.active) {
                    this.data = cloneDeep(this.event);
                    this.showDelete = false;
                    this.showRecurringDelete = false;
                    this.showHardDelete = false;
                    this.extendedMode = false;
                    this.editAll = true;

                    if (this.data.id !== null && this.data.id !== undefined) {
                        this.editMode = false;
                        this.extendedMode = false;

                        if (this.data.recurring_hash !== null) {
                            this.data.recurring_event = true;
                        }
                    } else {
                        this.editMode = true;
                        this.extendedMode = false;
                    }
                }
            },
            'data.is_whole_day': function (val) {

                if (val) {
                    this.start_at = moment(this.start_at).set("hour", 0).set("minute", 0).set("second", 0).format('YYYY-MM-DD HH:mm:ss');
                }

                if (this.startPickerKey === 1) {
                    this.startPickerKey = 2;
                    this.endPickerKey = 2;
                } else {
                    this.startPickerKey = 1;
                    this.endPickerKey = 1;
                }
            }
        },
        filters: {
            date(value) {
                return moment(value).format('DD.MM.YYYY');
            },
            dateTime(value) {
                return moment(value).format('DD.MM.YYYY HH:mm') + ' Uhr';
            }
        }
    }
</script>

<style lang="scss">
    .event-modal {
        .vs-popup {
            max-width: 500px;
            width: 100%;
        }

        .event-form-col {
            flex: 1;
        }

        &.is--extended {
            .vs-popup {
                max-width: 980px;
                width: 100%;
            }
            .event-form-col {
                padding-left: 15px;
            }

            .event-form-col:first-child {
                border-right: solid 1px #eee;
                padding-right: 15px;
                padding-left: 0;
            }
        }

        .recurring-event-form {
            border-bottom: solid 1px #eee;
        }
    }
</style>