<template>
    <div class="user-selector">
        <v-select
                v-model="propValue"
                :placeholder="placeholder"
                :options="options"
                autowidth
                :multiple="multiple"
                :clearable="true"
                label="name">
            <template #selected-option="{ detail }">
                {{detail.first_name}}   {{detail.last_name}}
            </template>
            <template #option="{ detail }">
                {{detail.first_name}}   {{detail.last_name}}
            </template>
        </v-select>
    </div>
</template>

<script>
    import ApiService from "../../api";
    import vSelect from 'vue-select'

    export default {
        name: "UserSelector",

        components: {
            'v-select': vSelect,
        },

        props: {
            value: {
                default: null
            },
            placeholder: {
                type: String,
                default: 'Mitarbeiter wählen...'
            },
            multiple: {
                type: Boolean,
                default: false
            },
        },

        data() {
            return {
                propValue: null,
                options: []
            }
        },

        created(){
            this.fetchUsers();
        },

        methods: {
            fetchUsers() {
                ApiService.get('users').then(response => {
                    if(response.data.status !== 'success') {
                        return;
                    }

                    this.options = response.data.result;


                    if(this.value !== null) {
                        if(this.multiple) {
                            this.propValue = [];
                            this.value.forEach(item => {
                                this.propValue.push(this.options.find(option => option.id === item.id));
                            });
                        } else {
                            this.propValue = this.options.find(item => item.id === this.value.id);
                        }
                    }
                });
            },

            emitPropValue(value) {
                this.$emit('input', value);
                this.$emit('change', value);
            },
        },

        watch: {
            propValue: {
                handler: 'emitPropValue'
            },
        }
    }
</script>

<style lang="scss">

</style>