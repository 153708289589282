<template>
    <div class="recurring-event-form">

        <div class="flex items-center mb-4">
            <div class="pr-4 w-1/6">Interval:</div>
            <v-select
                    v-model="data.recurring_config.interval_typ"
                    :options="recurringTypes"
                    autowidth
                    class="w-1/2"
                    :clearable="true"
                    label="label">
            </v-select>
        </div>

        <template v-if="data.recurring_config.interval_typ">
            <div class="flex items-center mb-4">
                <div class="pr-4 w-1/6">Alle:</div>
                <v-select
                        v-model="data.recurring_config.interval"
                        :options="typeOptions"
                        autowidth
                        :clearable="true"
                        class="w-1/2"
                        label="label">
                </v-select>
                <div class="pl-3" v-if="data.recurring_config.interval_typ">{{data.recurring_config.interval_typ.option_name}}</div>
            </div>

            <div class="flex items-center flex-wrap" v-if="data.recurring_config.interval_typ && data.recurring_config.interval_typ.id == 'weekly'">
                <div class="w-1/4 mb-3" v-for="weekDay in dayOptions">
                    <vs-checkbox v-model="data.recurring_config.week_day" class="ml-0" :vs-value="weekDay.id">{{weekDay.short}}</vs-checkbox>
                </div>
            </div>

            <template v-if="data.recurring_config.interval_typ &&data.recurring_config.interval_typ.id == 'monthly' || data.recurring_config.interval_typ.id == 'yearly'">
                <div class="flex items-center mb-4" v-if="data.recurring_config.interval_typ.id == 'yearly'">
                    <div class="w-1/6">Im :</div>
                    <v-select
                            v-model="data.recurring_config.month"
                            :options="monthOptions"
                            autowidth
                            :clearable="true"
                            class="w-1/3 ml-6">
                    </v-select>
                </div>

                <div class="flex items-center mb-4">
                    <div>
                        <vs-radio v-model="data.recurring_config.week_type" :vs-value="1"></vs-radio>
                    </div>
                    <div class="pr-4 w-1/6">An Tag:</div>
                    <v-select
                            v-model="data.recurring_config.day"
                            :options="monthDayOptions"
                            autowidth
                            :clearable="true"
                            class="w-1/3">
                    </v-select>
                </div>

                <div class="flex items-center">
                    <div>
                        <vs-radio v-model="data.recurring_config.week_type" :vs-value="2"></vs-radio>
                    </div>
                    <div class="pr-4 w-1/6">Am:</div>
                    <v-select
                            v-model="data.recurring_config.week"
                            :options="monthlyIntervalOptions"
                            autowidth
                            :clearable="true"
                            class="w-1/3 mr-2">
                    </v-select>
                    <v-select
                            v-model="data.recurring_config.month_week_day"
                            :options="dayOptions"
                            autowidth
                            :clearable="true"
                            class="w-3/8"
                            label="label">
                    </v-select>
                </div>
            </template>

            <div class="flex items-center mt-4">
                <div class="pr-4 w-1/6">Ende:</div>
                <flat-pickr v-model="data.recurring_config.until"
                            :config="datePickerConfig"
                            class="w-1/2"></flat-pickr>
            </div>
        </template>
    </div>
</template>

<script>
    import cloneDeep from "lodash/cloneDeep";
    import FlatPickr from "vue-flatpickr-component/src/component";
    import {German} from 'flatpickr/dist/l10n/de.js';
    import vSelect from 'vue-select'
    import moment from 'moment';
    import Recurring from "./recurring";

    export default {
        components: {FlatPickr, 'v-select': vSelect},
        name: "RecurringEventFrom",
        props: ['event'],
        data() {
            return {
                data: {
                    recurring_config: {
                        interval_typ: {id: 'daily', 'label': 'Täglich', option_name: 'Tag(e)'},
                        interval: {id: 1, 'label': 1},
                        month: {id: 1, label: 'Januar'},
                        day: 1,
                        week_day:  [],
                        month_week_day:  {id: 1, label: 'Montag', short: 'Mo'},
                        week: {id: 1, label: 'Ersten'},
                        week_type: 1,
                        until: moment().format('YYYY-MM-DD')
                    }
                },
                ...Recurring.options
            }
        },
        created() {
            this.updateHandler(this.data.recurring_config);
        },
        computed: {
            datePickerConfig() {
                return {
                    altFormat: 'd.m.Y',
                    dateFormat: 'Y-m-d',
                    altInput: true,
                    allowInput: true,
                    enableTime: false,
                    locale: German
                };
            },
            monthDayOptions() {
                const days = [];

                for (let i = 1; i <= 31; i++) {
                    days.push(i)
                }

                return days;
            },
        },

        methods: {
            updateHandler(data) {
                if(data.interval_typ.id === 'monthly' || data.interval_typ.id === 'yearly') {
                    return this.$emit('update', {
                        day: data.day,
                        interval: data.interval.id,
                        interval_typ: data.interval_typ.id,
                        month: data.month.id,
                        until: data.until,
                        week: data.week.id,
                        week_day: data.month_week_day.id,
                        week_type:  data.week_type

                    });
                }

                return this.$emit('update', {
                    interval: data.interval.id,
                    interval_typ: data.interval_typ.id,
                    month: data.month.id,
                    until: data.until,
                    week: data.week.id,
                    week_day: data.week_day,
                    week_type:  data.week_type
                });
            }
        },

        watch: {
            'data.recurring_config': {
                deep: true,
                handler: function(data) {
                 this.updateHandler(data);
                }
            }
        }
    }
</script>

<style scoped>

</style>